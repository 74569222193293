import React, { useEffect, useState, useCallback } from 'react';
import { Search } from '@mui/icons-material';
import { CalendarPickerSkeleton, LocalizationProvider, DatePicker } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, Fab, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { DataGrid, deDE, enUS, nlNL } from '@mui/x-data-grid';
import { useLazySearchTagsByCameraQuery } from '../../api/graphqlApi';
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next';
import { isEmpty, normalizeWeatherCode } from '../Utilities';
import DayjsAdapter from '@mui/lab/AdapterDayjs';
import Searchbar from './Searchbar';
import i18next from 'i18next';
import dayjs from "dayjs"

let data = {}

export default function SearchTag({ isSearchUpdated, setSearchUpdated, onOpenTagModal, disabled, loading, minDate, maxDate, timestamp }) {

    const { CAMERAS, WEATHER_CATEGORY } = window.conf
    const { t } = useTranslation()

    const [searchTagsByCamera, { isFetching: loadingSearchedData }] = useLazySearchTagsByCameraQuery()
    const [open, setOpen] = useState(false);
    const [selectedCameras, setSelectedCameras] = useState(CAMERAS); // get data for all cams initially
    const [searchSpan, setSearchSpan] = useState([timestamp, timestamp])
    const [searchCategory, setSearchCategory] = useState(""); // eslint-disable-line no-unused-vars
    const [searchDescription, setSearchDescription] = useState(""); // eslint-disable-line no-unused-vars
    const [searchedData, setSearchedData] = useState([]);
    const [localeText, setLocaleText] = useState();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const loadingButton = loading
    const loadingContent = loadingSearchedData
    const databaseDateFormat = 'YYYY-MM-DD'

    useEffect(() => {
        if (searchSpan[0] !== timestamp || searchSpan[1] !== timestamp) {
            setSearchSpan([timestamp, timestamp])
        }
    }, [timestamp, open]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (i18next.language === 'de') {
            setLocaleText(deDE.components.MuiDataGrid.defaultProps.localeText)
        } else if (i18next.language === 'en') {
            setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText)
        } else if (i18next.language === 'nl') {
            setLocaleText(nlNL.components.MuiDataGrid.defaultProps.localeText)
        }
    }, [t])

    useEffect(()=> {
        if (isSearchUpdated && !isEmpty(data)) { // only fetch when search results is to be updated and object is not empty
            getSearchDebounced(data)
        }
    }, [isSearchUpdated]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClickOpen = () => {
        setOpen(true)
        setSelectedCameras(CAMERAS)

        var dateDatabase = timestamp.format(databaseDateFormat)

        data = {
            cameras: CAMERAS,
            startDate: dateDatabase,
            endDate: dateDatabase,
            category: "",
            description: ""
        }

        getSearchDebounced(data)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCameraChange = (event) => {
        const value = event.target.value
        if (value?.length) { // change (and search) only when at least one camera was selected
            setSelectedCameras(prevState => {
                data = { ...data, cameras: value }
                return value
            })
            getSearchDebounced(data)
        }
    }

   const onChangeStartDate = (startDate) => {
        if (startDate) {
            setSearchSpan(prevState => {
                const updatedDate = [startDate, prevState[1]]
                data = {...data, startDate: updatedDate[0].format(databaseDateFormat)}
                return updatedDate
            })
            getSearchDebounced(data)
        }
   }

   const onChangeEndDate = (endDate) => {
        if (endDate) {
            setSearchSpan(prevState => {
                const updatedDate = [prevState[0], endDate]
                data = {...data, endDate: updatedDate[1].format(databaseDateFormat)}
                return updatedDate
            })
            getSearchDebounced(data)
        }
    }

    const setCategory = (value) => {
        setSearchCategory(prevState => {
            data = {...data, category: value}
            return value
        });
        getSearch(data) // no need for use debounced fetchData because included in Searchbar
    };

    const setDescription = (value) => {
        setSearchDescription(prevState => {
            data = {...data, description: value}
            return value
        })
        getSearch(data) // no need for use debounced fetchData because included in Searchbar
    }

    const getSearchDebounced = useDebouncedCallback((data) => {
        getSearch(data)
        setSearchUpdated(false)
    }, 500)

    const getSearch = useCallback(async(data) => {
        try {
            var archivePicturesDetailsByCamera = await searchTagsByCamera({ data: data })
            var tags = 
                archivePicturesDetailsByCamera?.data?.map((details, index) =>
                    details?.data?.map(elem => {

                        const camera = CAMERAS.find(camera => camera.featureDetailId === details.featureDetailId)
                        let isWeather = elem.kategorie === WEATHER_CATEGORY
                        let category = isWeather ? t(`weather.weather`) : elem.kategorie
                        let description = isWeather ? t(`weather.conditions.${normalizeWeatherCode(elem.beschreibung)}`) : elem.beschreibung
                        let weather = elem.weatherArchives?.[0]

                        if (isWeather && !!weather) {
                            description = `${Math.round(weather.temp)}°, ${t(`weather.conditions.${normalizeWeatherCode(weather.weatherCode)}`)}, ${t(`weather.wind`)} ${weather.windSpeed} km/h, ${t(`weather.humidity`)} ${weather.humidity}%`
                        }

                        return ({
                            id: elem.id,
                            cameraName: camera?.name !== "" ? camera.name : `${t("events.camera")} ${camera?.id + 1}`, // cameraName: camera?.name || '',
                            camera: camera,
                            datetime: dayjs(`${elem.archivePictures.datum.substring(0,10)} ${elem.archivePictures.datum.substring(11,16)}`).format(t("date.format.full_date_time")), // `YYYY-MM-DD HH:mm` to given format
                            category: category,
                            description: description
                        })
                    })
                ).flat().filter(elem => elem) // filter empty tags

            setSearchedData(tags ?? [])
        } catch(error) {
            console.error(error)
        }
    }, [searchTagsByCamera]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleRowClick = (event) => {
        onOpenTagModal(event.row)
    }

    var columns = [
        {
            field: 'datetime',
            headerName: t("date.datetime"),
            width: 200
        },
        {
            field: 'category',
            headerName: t("events.category"),
            width: 200
        },
        {
            field: 'description',
            headerName: t("events.description"),
            flex: 1,
            minWidth: 200
        }
    ]

    return (
        <Box>
            <Tooltip title={t('events.search_events')} placement="top" arrow>
                <Box component="span">
                    <Fab 
                        area-label="Search Tag"
                        size="small"
                        color="primary"
                        onClick={handleClickOpen}
                        disabled={loadingButton || disabled}
                        sx={{ mr: '2px', ml: '2px' }}
                    >
                        <Search />
                    </Fab>
                </Box>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth='lg'
            >
                <DialogContent sx={{ p: 2 }}>
                    
                    <Grid container>

                        <Grid item xs={12} sx={{ pb: 1 }} /> {/* An empty grid item. A trick to fix the lable layout issue on FormControl */}
                        
                        {CAMERAS.length > 1 && (
                            <Grid item xs={12} sx={{ pb: 2 }}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id='selectCameraLabel'>{t("events.select_cams")}</InputLabel>
                                    <Select
                                        multiple
                                        labelid='selectCameraLabel'
                                        label={t("events.select_cams")}
                                        value={selectedCameras}
                                        onChange={handleCameraChange}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        {CAMERAS.map(camera => (
                                            <MenuItem
                                                key={camera.id}
                                                value={camera}
                                            >
                                                {camera.name !== "" ? camera.name : `${t("events.camera")} ${camera.id + 1}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Box>
                                <LocalizationProvider
                                    dateAdapter={DayjsAdapter}
                                    locale={i18next.language}
                                >
                                    <Box sx={{ display: 'flex'}}>
                                        <DatePicker
                                            label={t("date.start_date")}
                                            inputFormat={(t("date.format.full"))}
                                            mask={t("date.mask.full")}
                                            renderInput={props => 
                                                <TextField {...props} sx={{
                                                    width: "100%", userSelect: "none"
                                                }} />    
                                            }
                                            value={searchSpan[0]}
                                            onChange={onChangeStartDate}
                                            cancelText={t("actions.cancel")}
                                            toolbarTitle={t("date.select_date")}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            renderLoading={() => <CalendarPickerSkeleton />}
                                        />
                                            <p style={{ 
                                                paddingLeft:'12px', 
                                                paddingRight:'12px', 
                                                height:"100%",
                                                alignItems:'center', 
                                            }}>
                                                {t("date.to")}
                                            </p>
                                        <DatePicker
                                            label={t("date.end_date")}
                                            inputFormat={(t("date.format.full"))}
                                            mask={t("date.mask.full")}
                                            renderInput={props => 
                                                <TextField {...props} sx={{
                                                    width: "100%", userSelect: "none"
                                                }} />    
                                            }
                                            value={searchSpan[1]}
                                            onChange={onChangeEndDate}
                                            cancelText={t("actions.cancel")}
                                            toolbarTitle={t("date.select_date")}
                                            minDate={searchSpan[0]}
                                            maxDate={maxDate}
                                            renderLoading={() => <CalendarPickerSkeleton />}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Searchbar
                                action={setCategory}
                                placeholder={t("events.category")}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Searchbar 
                                action={setDescription}
                                placeholder={`${t("events.description")} (${t("events.weather_hint")})`}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <Box sx={{ 
                                height: 300, 
                                maxHeight: 300, 
                                width: '100%', 
                                display: 'flex', 
                                flexGrow: 1 
                            }}>
                                
                                        <DataGrid
                                            sx={{
                                                '.MuiDataGrid-row' : {
                                                    '& :hover' : {
                                                        cursor: 'pointer'
                                                    }
                                                }
                                            }}
                                            localeText={localeText}
                                            rows={searchedData}
                                            onRowClick={handleRowClick}
                                            columns={CAMERAS.length > 1
                                                ? [
                                                    {
                                                        field: 'cameraName',
                                                        headerName: t("events.camera"),
                                                        width: 200
                                                    },
                                                    ...columns
                                                ]
                                                : columns}
                                            disableSelectionOnClick
                                            loading={loadingContent}
                                        />
                            </Box>
                        </Grid>

                        <Grid 
                            item 
                            xs={12}
                            sx={{
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'end'
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                            >
                                {t("actions.close")}
                            </Button>
                        </Grid>
                        
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    )
}